<template>
  <div>
    <v-dialog v-model="dialog" persistent width="500" scrollable>
      <form @submit.prevent="save">
        <v-card :loading="btnLoading" :disabled="btnLoading">
          <v-card-title>
            {{ title }}
            <v-spacer />
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

          <v-divider />

          <v-card-text class="pa-4">
            <v-text-field
              label="Code"
              :error-messages="errors.code"
              :prepend-icon="'mdi-key'"
              type="text"
              v-model="form.code"
            />
            <v-text-field
              label="Nom du Magasin"
              :error-messages="errors.pos_name"
              :prepend-icon="'mdi-store'"
              type="text"
              v-model="form.pos_name"
            />

            <v-text-field
              label="Nom du propriétaire"
              :error-messages="errors.owner_name"
              :prepend-icon="'mdi-account'"
              type="text"
              v-model="form.owner_name"
            />

            <v-text-field
              label="Téléphone du propriétaire"
              :error-messages="errors.owner_phone"
              :prepend-icon="'mdi-phone'"
              v-mask="phoneMask"
              :placeholder="phoneMask"
              type="text"
              v-model="form.owner_phone"
              hint="Exemple: +213669000000"
              persistent-hint
            />
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="btnLoading"
              depressed
              type="submit"
            >
              <v-icon left>mdi-content-save</v-icon>
              Sauvgarder
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>
  
  <script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      phoneMask: "+213#########",
      title: null,
      dialog: false,
      isWilayasLoading: false,
      btnLoading: false,

      //wilayas: [],

      errors: {},
      form: {
        code: "",
        pos_name: "",
        owner_name: "",
        owner_phone: "",
      },
      database_id: "",
    };
  },
  methods: {
    open(database_id, item) {
      this.errors = {};
      this.database_id = database_id;

      this.title = "Ajouter un point de vente";
      this.form = {
        id: null,
      };

      if (item) {
        this.title = "Modifier un point de vente";
        this.form = {
          id: item.id,
          code: item.code,
          pos_name: item.pos_name,
          owner_name: item.owner_name,
          owner_phone: item.owner_phone,
        };
      }

      this.dialog = true;

      // if (!this.wilayas.length) {
      //   this.getWilayas();
      // }
    },
    close() {
      this.dialog = false;
    },

    //   getWilayas() {
    //     this.isWilayasLoading = true;
    //     HTTP.get("/v1/users/wilayas")
    //       .then((res) => {
    //         this.isWilayasLoading = false;
    //         this.wilayas = res.data.data;
    //       })
    //       .catch((err) => {
    //         this.isWilayasLoading = false;
    //         console.log(err);
    //       });
    //   },
    save() {
      this.errors = {};
      this.isLoading = true;
      this.btnLoading = true;

      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });

      var url = "/v1/database/addClientSalepoint/" + this.database_id;
      if (this.form.id != null) {
        url = "/v1/database/updateClientSalepoint/" + this.form.id;
      }

      HTTP.post(url, formData).then(() => {
          this.isLoading = false;
          this.btnLoading = false;
          this.$successMessage = "Cet élément a été enregistré avec succès.";
          this.close();
          this.$emit("refresh");

          //this.$refs.file.reset();
        }).catch((err) => {
          this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          }

          this.isLoading = false;
          this.btnLoading = false;
          console.log(err);
        });
    },

    uploadFile(file) {
      this.form.image = file;
    },
  },
};
</script>