<template>
  <div>
    <v-dialog v-model="dialog" persistent width="500" scrollable>
      <form @submit.prevent="save">
        <v-card :loading="btnLoading" :disabled="btnLoading">
          <v-card-title>
            Envoyer un sms pour:  <span class="gifty--text"> {{ clientSalepoint ? clientSalepoint.owner_name : ""}}</span>
            <v-spacer />
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

          <v-divider />

          <v-card-text class="pa-4">
            <v-textarea
                rows="2"
                filled
                @input="
                  errors && errors.message ? (errors.message = '') : false
                "
                :error-messages="errors.message"
                v-model="form.message"
                label="Message *"
              />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="btnLoading"
              depressed
              type="submit"
            >
              <v-icon left>mdi-send</v-icon>
              Envoyer
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>
    

<script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      errors: {},
      form: {
        message: "",
      },
      clientSalepoint: null,
      dialog: false,
      btnLoading: false,
    };
  },
  methods: {
    open(salepoint) {
      this.clientSalepoint = salepoint;
      this.dialog = true;
    },
    close() {
      this.form.message = "";
      this.dialog = false;
    },
    save() {
      this.errors = {};
      this.btnLoading = true;
      HTTP.post("/v1/database/sendSms/" + this.clientSalepoint.id, this.form)
        .then(() => {
          this.btnLoading = false;
          
          this.$successMessage = "Sms envoyé avec succès.";
          this.close();
        })
        .catch((err) => {
          console.log(err);

          this.errors = err.response.data.errors;
          this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
          this.btnLoading = false;
          console.log(err);
        });
    },
  },
};
</script>