<template>
  <div>
    <v-dialog v-model="dialogSms" max-width="600" persistent>
      <v-card>
        <v-card-title>
          Séléctionnez des POS
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <v-alert border="left" color="error" dark v-if="error">
            {{ error }}
          </v-alert>

          <div v-if="loading">
            <v-skeleton-loader type="article,actions" />
          </div>
          <div v-else>
            <div v-if="selected_salepoints.length === 0">
              <div
                v-if="
                  this.filter.database_id == 'all' ||
                  this.filter.database_id == 'client_fournissseur'
                "
              >
                <v-select
                  label="Versions"
                  :loading="isVersionsLoading"
                  :items="versions"
                  multiple
                  v-model="filter.versions"
                  class="mb-3"
                  hide-details=""
                  clearable
                  chips
                  small-chips
                  deletable-chips
                />

                <v-select
                  label="Catégories"
                  :loading="isCategoryLoading"
                  :items="categories"
                  item-value="id"
                  item-text="name"
                  multiple
                  v-model="filter.categories"
                  class="mb-3"
                  hide-details=""
                  clearable
                  chips
                  small-chips
                  deletable-chips
                />

                <v-select
                  label="Role"
                  :loading="isRoleLoading"
                  :items="roles"
                  item-value="id"
                  item-text="name"
                  multiple
                  v-model="filter.roles"
                  class="mb-5"
                  hide-details=""
                  clearable
                  chips
                  small-chips
                  deletable-chips
                />
                <div>
                  <v-range-slider
                    v-if="!rangeLoading"
                    v-model="filter.range"
                    :max="max"
                    :min="0"
                    hide-details
                    class="align-center mt-5 pt-5 mb-4"
                    thumb-label="always"
                  >
                    <template v-slot:thumb-label="item">
                      <v-btn
                        class="primary mt-4"
                        small
                        elevation="0"
                        style="font-size: 10px"
                      >
                        {{ CurrencyFormatting(item.value) }} DZ
                      </v-btn>
                    </template>
                  </v-range-slider>
                </div>

                <v-select
                  label="Base de données"
                  :loading="isDBLoading"
                  :items="databases"
                  item-value="id"
                  item-text="name"
                  multiple
                  v-model="filter.databases"
                  class="mb-3"
                  hide-details=""
                  clearable
                  chips
                  small-chips
                  deletable-chips
                />
              </div>

              <v-autocomplete
                v-model="filter.selectedWilayas"
                @change="filterRegion"
                label="Wilaya"
                multiple
                chips
                small-chips
                clearable
                deletable-chips
                :items="$store.state.wilayas"
                item-text="name"
                item-value="id"
              />
              <v-autocomplete
                label="Commune"
                :disabled="filter.selectedWilayas.length === 0"
                clearable
                chips
                small-chips
                deletable-chips
                multiple
                v-model="filter.selectedCommunes"
                :items="communes"
                item-value="id"
                item-text="name"
              ></v-autocomplete>
              <label>Filtrer par type :</label>
              <v-chip-group v-model="filter.salepoint_type_ids" column multiple>
                <v-chip
                  filter
                  color="blue"
                  outlined
                  v-for="el in salepoint_types"
                  :key="el.id"
                  :value="el.id"
                >
                  {{ el.name }}
                </v-chip>
              </v-chip-group>
              <v-radio-group
                hide-details
                v-model="filter.has_gifty"
                v-if="this.filter.database_id != 'client_fournissseur'"
              >
                <v-radio :value="'yes'" label="Avec compte Gifty" />
                <v-radio :value="'no'" label="Sans compte Gifty" />
                <v-radio :value="'all'" label="Les deux" />
              </v-radio-group>
              <v-alert v-if="msgNoData" color="warning" text class="mt-3">
                <strong>OOPS !</strong> Aucun enregistrement correspondant
                trouvé.
              </v-alert>
              <div class="d-flex justify-space-between">
                <v-spacer />
                <v-btn
                  color="primary"
                  class="rounded-lg text-none mt-3"
                  large
                  depressed
                  @click="filterSalepoints"
                >
                  Filtrer
                </v-btn>
              </div>
            </div>
            <div v-else>
              <div>
                <v-alert type="info" text>
                  <p>
                    Total POS sélectionnées :
                    <v-chip color="blue" small dark>{{ total_count }}</v-chip>
                  </p>
                  <p>
                    Prix unit :
                    <v-chip color="blue" small dark>{{ sms_price }} Da</v-chip>
                  </p>
                  <p>
                    Total :
                    <v-chip color="blue" small dark
                      >{{ total_price }} Da</v-chip
                    >
                  </p>
                </v-alert>

                <v-tabs v-model="tab" centered icons-and-text>
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab href="#tab-form"> Formulaire </v-tab>

                  <v-tab href="#tab-clients"> Point de ventes </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-form">
                    <v-card flat>
                      <v-card-text>
                        <v-textarea
                          rows="2"
                          filled
                          @input="
                            errors && errors.content
                              ? (errors.content = '')
                              : false
                          "
                          :error-messages="errors.content ? errors.content : ''"
                          v-model="content"
                          label="Message *"
                        />
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item value="tab-clients">
                    <v-card flat>
                      <v-card-text>
                        <div v-if="salepoints.length > 0">
                          <v-data-table
                            :items="salepoints"
                            :hide-default-header="false"
                            :hide-default-footer="false"
                            item-key="id"
                            class="elevation-0"
                          >
                            <template v-slot:item="{ item }">
                              <tr>
                                <td class="font-weight-bold">
                                  <v-checkbox
                                    v-model="selected_salepoints"
                                    :value="item"
                                    :value-comparator="comparator"
                                  />
                                </td>
                                <td class="font-weight-bold">
                                  {{ item.id }}
                                </td>

                                <td class="font-weight-bold">
                                  <v-avatar size="35">
                                    <v-img
                                      :src="require('@/assets/avatar.png')"
                                    />
                                  </v-avatar>
                                </td>

                                <td class="font-weight-bold text-no-wrap">
                                  {{ item.owner_name }}
                                </td>
                                <td class="font-weight-bold text-no-wrap">
                                  {{ item.owner_phone }}
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </div>

                        <div v-else>
                          <div class="text-center">
                            <v-avatar tile size="200">
                              <v-img
                                :src="require('@/assets/database.svg')"
                              ></v-img>
                            </v-avatar>
                            <h3 class="text--primary d-block mb-2">OOPS !</h3>
                            <p>Aucun enregistrement correspondant trouvé.</p>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>

                <div class="d-flex justify-space-between">
                  <v-spacer />
                  <v-btn
                    text
                    class="rounded-lg text-none mt-3 mr-2"
                    large
                    @click="selected_salepoints = []"
                  >
                    <v-icon left>mdi-arrow-left</v-icon>
                    Retour
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="rounded-lg text-none mt-3"
                    :loading="btnLoading"
                    large
                    depressed
                    @click="send"
                  >
                    <v-icon left>mdi-send-outline</v-icon>
                    Envoyer
                  </v-btn>
                </div>

                <!-- Form -->
              </div>

              <!-- Form -->
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";

export default {
  props: ["dialogSms"],
  data() {
    return {
      tab: null,
      error: "",
      isDBLoading: false,
      isRoleLoading: false,
      isCategoryLoading: false,
      isCountriesLoading: false,
      isVersionsLoading: false,
      rangeLoading: true,
      roles: [],
      categories: [],
      databases: [],
      versions: [],
      range: [],

      filter: {
        database_id: this.$route.params.id,
        has_gifty: "all",

        salepoint_type_ids: [],
        selectedCommunes: [],
        selectedWilayas: [],

        roles: [],
        categories: [],
        databases: [],
        versions: [],
        range: [],
      },
      salepoint_ids: [],

      salepoints: [],
      selected_salepoints: [],

      btnLoading: false,
      loading: false,
      total_salepoint: "",
      sms_price: 0,
      content: "",
      msgNoData: false,
      errors: {},
      fRegions: [],
      salepoint_types: [],
      isTypesLoading: false,
    };
  },

  computed: {
    wilayas() {
      return this.$store.getters.getWilayas;
    },

    communes() {
      return this.$store.getters.getCommunes;
    },

    total_price() {
      return this.selected_salepoints.length * this.sms_price;
    },
    total_count() {
      return this.selected_salepoints.length;
    },
  },
  methods: {
    filterRegion() {
      var hash_array = [];

      var numeric_array = [];
      for (var item in this.filter.selectedWilayas) {
        numeric_array.push(this.filter.selectedWilayas[item]);
      }
      console.log(numeric_array);
      this.$store.dispatch("fetchCommunes", {
        filter: numeric_array,
      });
    },
    close() {
      this.error = "";
      this.$emit("update:dialogSms", false);
    },
    send() {
      this.btnLoading = true;
      var salepoint_ids = [];
      this.error = "";

      this.selected_salepoints.forEach((element) => {
        salepoint_ids.push(element.id);
      });
      var url = "/database/send-sms";

      if (this.filter.database_id == "all" || this.filter.database_id == "client_fournissseur") {
        url = "v1/database/sendSmsForAllDatabase";
      }

      HTTP.post(url, {
        content: this.content,
        salepoint_ids: salepoint_ids,
        database_id: this.filter.database_id,
        total_price: this.total_price,
        price: this.sms_price,
      })
        .then(() => {
          this.btnLoading = false;
          this.$successMessage = "Ces messages a été envoyées avec succès";
          this.close();
          this.selected_salepoints = [];
          this.salepoints = [];
          this.$emit("reload");
        })
        .catch((err) => {
          this.btnLoading = false;
          this.error = err.response.data.message;
          this.errors = err.response.data.errors;
          //    console.log(err);
        });
    },
    filterSalepoints() {
      this.loading = true;
      HTTP.post("/database/sms-filter-salepoint", this.filter)
        .then((res) => {
          this.salepoint_ids = res.data.salepoint_ids;
          this.total_salepoint = res.data.total_salepoint;
          this.sms_price = res.data.sms_price;
          this.msgNoData = res.data.salepoint_ids.length <= 0;
          this.salepoints = res.data.salepoints;
          this.selected_salepoints = res.data.salepoints;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    comparator(a, b) {
      return a.id == b.id;
    },
    fetchTypes() {
      this.isTypesLoading = true;

      HTTP.get("/salepoint-type")
        .then((res) => {
          this.salepoint_types = res.data;
          this.isTypesLoading = false;
        })
        .catch((err) => {
          this.isTypesLoading = false;
          console.log(err);
        });
    },

    getCategories() {
      this.isCategoryLoading = true;
      HTTP.get("/v1/users/categories")
        .then((res) => {
          this.isCategoryLoading = false;
          this.categories = res.data.data;
        })
        .catch((err) => {
          this.isRoleLoading = false;
          console.log(err);
        });
    },
    getRoles() {
      this.isRoleLoading = true;
      HTTP.get("/v1/users/roles")
        .then((res) => {
          this.isRoleLoading = false;
          this.roles = res.data.data;
        })
        .catch((err) => {
          this.isRoleLoading = false;
          console.log(err);
        });
    },
    getDatabases() {
      this.isDBLoading = true;
      HTTP.get("/v1/users/databases")
        .then((res) => {
          this.isDBLoading = false;
          this.databases = res.data.data;
        })
        .catch((err) => {
          this.isDBLoading = false;
          console.log(err);
        });
    },

    getVersions() {
      this.isVersionsLoading = true;
      HTTP.get("/v1/users/versions")
        .then((res) => {
          this.isVersionsLoading = false;
          this.versions = res.data.data;
        })
        .catch((err) => {
          this.isVersionsLoading = false;
          console.log(err);
        });
    },

    getRange() {
      this.rangeLoading = true;
      HTTP.get("v1/users/min_max_accounts")
        .then((res) => {
          this.max = res.data.max;
          this.filter.range = [res.data.min, res.data.max];

          this.rangeLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.rangeLoading = false;
        });
    },
  },

  mounted() {
    if (!this.databases.length) {
      this.getDatabases();
    }
    if (!this.categories.length) {
      this.getCategories();
    }
    if (!this.versions.length) {
      this.getVersions();
    }
    if (!this.filter.range.length) {
      this.getRange();
    }
    if (!this.filter.roles.length) {
      this.getRoles();
    }

    this.fetchTypes();
  },
};
</script>

<style scoped></style>
