<template>
  <v-dialog max-width="500" scrollable v-model="dialog" persistent>
    <v-card>
      <v-card-title>
        <v-icon left>mdi-tune</v-icon>
        Affecter un service
        <v-spacer />

        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4" v-if="loading">
        <v-skeleton-loader
          type="article, actions"
        ></v-skeleton-loader>
      </v-card-text>
      <div v-else>
        <v-card-text class="pa-4">
            <p class="text-center font-weight-bold pink--text darken-2">
              Compte : {{ name }}
            </p>
          <v-row>
            <v-col cols="12">
              <div>
                <v-autocomplete
                  hide-details
                  label="Services"
                  clearable
                  chips
                  small-chips
                  deletable-chips
                  multiple
                  v-model="form.services_ids"
                  :items="services"
                  item-value="id"
                  item-text="name"
                ></v-autocomplete>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="btnLoading"
            color="primary"
            :disabled="isDisabled"
            depressed
            block
            @click="send"
          >
            Envoyer
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
    
<script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      dialog: false,
      btnLoading: false,
      loading: false,
      name: null,
      form: {
        user_id: null,
        services_ids: [],
      },
    };
  },

  methods: {
    send() {
      this.btnLoading = true;
      HTTP.post("/service/user/setServices", this.form)
        .then((res) => {
          console.log(res);
          this.dialog = false;

          this.btnLoading = false;
          this.$successMessage = "Affectation avec succès";
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
        });
    },
    getUserServices(id) {
      this.loading = true;
      HTTP.get("service/user/getUserServices/" + id, this.form)
        .then((res) => {
          console.log(res);
          this.form.services_ids = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    open(user_id, name) {
      this.dialog = true;
      this.name= name;
      this.form.user_id = user_id;
      this.getUserServices(user_id);
    },
  },
  computed: {
    isDisabled() {
      return Object.values(this.form).every(
        (x) => x === null || x === "" || x.length === 0
      );
    },
    services() {
      return this.$store.getters.getServices.data;
    },
  },
};
</script>
    
    