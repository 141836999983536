<template>
  <v-dialog max-width="500" scrollable v-model="dialog" persistent>
    <v-card>
      <v-card-title>
        <v-icon left>mdi-currency-usd</v-icon>
        Montants Par Service
        <v-spacer />

        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4" v-if="loading">
        <v-skeleton-loader
          v-bind="attrs"
          type="article, actions"
        ></v-skeleton-loader>
      </v-card-text>
      <div v-else>
        <v-card-text class="pa-4">
          <v-simple-table v-if="account && account.service_balances.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left font-weight-bold">Service</th>
                  <th class="text-center font-weight-bold">Montant</th>
                  <th class="text-center font-weight-bold">Crédit / Payé </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="service_balance in account.service_balances"
                  :key="service_balance.id"
                >
                  <td class="font-weight-bold">
                    {{
                      service_balance.service
                        ? service_balance.service.name
                        : "-"
                    }}
                  </td>
                  <td class="pink--text font-weight-bold text-center">{{ CurrencyFormatting(service_balance.amount) }} DZD</td>
                  <td class="text-center font-weight-bold">{{ service_balance.paied ? "Payé" : "Crédit" }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else>
            <div class="text-center">
                    <v-avatar tile size="200">
                      <v-img :src="require('@/assets/database.svg')"></v-img>
                    </v-avatar>
                    <h3 class="text--primary d-block mb-2">OOPS !</h3>
                    <p>Aucun enregistrement correspondant trouvé.</p>
                  </div>
          </div>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>
 
<script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      account_id: null,
      account: null,
    };
  },

  methods: {
    open(account_id) {
      this.account_id = account_id;
      this.dialog = true;
      this.fetchData();
    },

    fetchData() {
      this.loading = true;
      HTTP.get("/v1/users/account/balances/" + this.account_id)
        .then((res) => {
          console.log(res);
          this.account = res.data.data;
          this.loading = false;
        })
        .catch((e) => {
            console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>