<template>
  <v-dialog max-width="400"
            scrollable
            v-model="dialog"
            persistent>

    <template v-slot:activator="{ on: menu, attrs }">
      <v-btn color="grey"
             text
             v-bind="attrs"
             v-on="{...menu }"
      >
        <v-icon left>mdi-tune</v-icon>
        Filter
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Filtrer par
        <v-spacer/>
        <v-btn
            small
            outlined
            text
            class="mr-3 ml-3 no-anim"
            @click="clearFilter"
        >Effacer
        </v-btn
        >
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">

            <v-autocomplete
                hide-details
                label="Categorie"
                clearable
                chips
                small-chips
                deletable-chips
                multiple
                v-model="filter.categories"
                @change="filterRegion"
                :items="categories"
                item-value="id"
                item-text="name"
                :loading="categoriesLoading"
                class="mb-2"
            ></v-autocomplete>

            <div v-if="client_fournisseur">
              <v-autocomplete
                  hide-details
                  label="Roles"
                  clearable
                  chips
                  small-chips
                  deletable-chips
                  multiple
                  v-model="filter.roles"
                  :items="roles"
                  item-value="value"
                  item-text="label"
              ></v-autocomplete>
            </div>


            <div v-else>


              <v-autocomplete
                  hide-details
                  label="Wilaya"
                  clearable
                  chips
                  small-chips
                  deletable-chips
                  multiple
                  v-model="filter.selectedWilayas"
                  @change="filterRegion"
                  :items="$store.state.wilayas"
                  item-value="id"
                  item-text="name"
                  class="mb-2"
              ></v-autocomplete>
              <v-autocomplete
                  hide-details
                  label="Commune"
                  :disabled="filter.selectedWilayas.length === 0"
                  clearable
                  chips
                  small-chips
                  deletable-chips
                  multiple
                  v-model="filter.selectedCommunes"
                  :items="communes"
                  item-value="id"
                  item-text="name"
              ></v-autocomplete>
              <v-radio-group hide-details v-model="filter.has_gifty">
                <v-radio :value="'yes'" label="Avec compte Gifty"/>
                <v-radio :value="'no'" label="Sans compte Gifty"/>
                <v-radio :value="'all'" label="Les deux"/>
              </v-radio-group>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            :disabled="isDisabled"
            depressed
            block
            @click="handleFilter"
        >
          Recherche
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from '@/http-common';

export default {
  data() {
    return {
      dialog: false,
      filter: {
        salepoint_type_ids: [],

        selectedCommunes: [],
        selectedWilayas: [],
        has_gifty: "all",
        period: [],
        startDate: "",
        endDate: "",
        roles: [],
        categories: []
      },
      // left
      modalStartDate: false,
      modalEndDate: false,
      panel: [0],

      roles: [
        {
          value: 2,
          label: "Client",
        },
        {
          value: 4,
          label: "Fournisseur",
        },
      ],

      categories: [],
      categoriesLoading: false
    };
  },

  methods: {
    handleFilter() {
      this.dialog = false;
      this.$emit("filter", this.filter);
      //this.clearFilter();
    },
    clearFilter() {
      this.filter = {
        selectedCommunes: [],
        selectedWilayas: [],
        has_gifty: "all",
        period: [],
        startDate: "",
        endDate: "",
        roles: []
      };
    },
    filterRegion() {
      var numeric_array = [];
      for (var item in this.filter.selectedWilayas) {
        numeric_array.push(this.filter.selectedWilayas[item]);
      }
      console.log(numeric_array);
      this.$store.dispatch("fetchCommunes", {
        filter: numeric_array,
      });
    },

    getCategories() {
      this.categoriesLoading = true;
      HTTP.get("/v1/users/categories").then((res) => {
        this.categories = res.data.data;
        this.categoriesLoading = false;
      }).catch((err) => {
        this.categoriesLoading = false;
        console.log(err);
      })
    }
  },
  computed: {
    isDisabled() {
      return Object.values(this.filter).every(
          (x) => x === null || x === "" || x.length === 0
      );
    },
    wilayas() {
      return this.$store.getters.getWilayas;
    },

    communes() {
      return this.$store.getters.getCommunes;
    },

    client_fournisseur() {
      return "client_fournissseur" === this.$route.params.id;
    },
  },
  mounted() {
    this.getCategories();
  }
};
</script>
  
  